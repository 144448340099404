import { isPlainObject, State } from "infrontjs";
import { Session } from "../../model/Session";
import { DvzApi } from "../../core/DvzApi.js";

//import './../../components/auth/auth-login-form';

class AuthLoginState extends State
{
    static ID = 'auth-login';

    canEnter()
    {
        return ( null === this.app.session );
    }

    getRedirectTo()
    {
        return '/dashboard';
    }

    async enter()
    {
        const contentHtml = await this.app.templateManager.get( '/tmpl/auth-login.html' );
        this.app.container.innerHTML = contentHtml;

        this.app.container.querySelector( '#btn-login' ).addEventListener( 'click', async ( e ) =>
        {
            const btn = e.target;
            btn.disabled = true;
            this.app.container.querySelector( 'alert-box' ).setAttribute( 'message', '' );

            let fd = new FormData( this.app.container.querySelector( '#form-login' ) ),
                fdJson = {};
            fd.forEach(( value, key) => fdJson[ key] = value );

            this.app.api.post(
                'v1/auth/session',
                fdJson,
                ( err, res ) => {
                    btn.disabled = false;
                    if ( err )
                    {
                        // General server error
                        this.app.container.querySelector( 'alert-box' ).setAttribute( 'message', 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.' );
                    }
                    else
                    {
                        if ( null !== ( err = DvzApi.getErrorFromResponse( res ) ) )
                        {
                            let errMsg = '';
                            switch( err.code )
                            {
                                case 403:
                                    errMsg = 'Nutzerdaten fehlerhaft.';
                                break;

                                case 404:
                                    errMsg = 'Nutzerdaten unbekannt.';
                                break;

                                default:
                                    errMsg = 'Fehler. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
                            }

                            this.app.container.querySelector( 'alert-box' ).setAttribute( 'message', errMsg );
                        }
                        else
                        {
                            this.app.session = new Session( res.data );
                            this.app.api.setSessionId( this.app.session.getSessionId() );
                            localStorage.removeItem( 'sessionId' );
                            localStorage.setItem( 'sessionId', this.app.session.id );
                            this.app.api.setSessionId( this.app.session.id );
                            this.app.router.redirect( '/dashboard' );
                        }
                    }
                }
            );
        });
    }
}

export { AuthLoginState };
