import { App } from "infrontjs";
import template from './template.html';

class AlertBox extends HTMLElement
{
    static get observedAttributes()
    {
        return [ 'message' ];
    }

    get message()
    {
        return this.getAttribute( 'message' ) ? this.getAttribute( 'message' ) : '';
    }

    constructor()
    {
        super();
        this._isConnected = false;
    }

    connectedCallback()
    {
        if ( false === this._isConnected )
        {
            this.root = this;
            this.app = App.get();
            this._isConnected = true;
            this.render();
        }
        //root.innerHTML =app.templateManager.compileTemplate( template, { imgLogo : dvzLogo, coverImg : coverImg } ) ;
    }

    disconnectedCallback()
    {}

    attributeChangedCallback( name, oldVal, newVal )
    {
        if ( this._isConnected )
        {
            this.render();
        }
    }

    render()
    {
        this.root.innerHTML = this.app.templateManager.getHtml( template, { message : this.message } );
    }

}

customElements.define('alert-box', AlertBox );
