import template from './template.html';

// -------------------------------------------
// Custom HTMLElement Definitions
// -------------------------------------------

/** @constructor */
function PageLoader () {
    const superCtor = (typeof Reflect === 'object') ? function () {
        return Reflect.construct(HTMLElement, arguments, this.constructor);
    } : function () {
        return HTMLElement.apply(this, arguments) || this;
    };
    this.self = superCtor.apply(this, arguments);
    return this.self;
}

PageLoader.prototype = Object.create(HTMLElement.prototype);
PageLoader.prototype.constructor = PageLoader;
Object.setPrototypeOf(PageLoader, HTMLElement);
Object.defineProperties(PageLoader, {
    observedAttributes: {
        get: () => ([])
    }
});

PageLoader.prototype.fadeIn = function()
{
    //this.shadowRoot.querySelector( '.rainbow-container' ).style.opacity = 0;
    this.style.display = 'block';
    this.fadingMode = 'in';
    setTimeout( () => { this.shadowRoot.querySelector( '.rainbow-container' ).style.opacity = 1; }, 10 );
};

PageLoader.prototype.fadeOut = function()
{
    this.fadingMode = 'out';
    setTimeout( () => { this.shadowRoot.querySelector( '.rainbow-container' ).style.opacity = 0; }, 10 );
};

PageLoader.prototype.onFadedOut = function()
{
    if ( this.style.display !== 'none' && this.fadingMode === 'out' )
    {
        this.style.display = 'none';
    }
};

// -------------------------------------------
// Custom HTMLElement (rest of the definitions)
// -------------------------------------------

// Defined at the bottom so that they can refer
// to private functions that are defined
// prior to this section using "const".
// (Ex. "setMessage", etc.)

function connectedCallback () {
    const el = document.createElement('template');
    const text = String.raw`${template}`;
    if (!text) throw new Error('No template.');
    el.innerHTML = text;

    const root = this.attachShadow({ mode: 'open' });
    root.appendChild(el.content.cloneNode(true));

    root.querySelector( '.rainbow-container' ).addEventListener( 'transitionend', this.onFadedOut.bind( this ) );
}

function disconnectedCallback () { }

function adoptedCallback () { }

function attributeChangedCallback (name, oldVal, newVal) {
}

PageLoader.prototype.connectedCallback = connectedCallback;
PageLoader.prototype.disconnectedCallback = disconnectedCallback;
PageLoader.prototype.adoptedCallback = adoptedCallback;
PageLoader.prototype.attributeChangedCallback = attributeChangedCallback;

customElements.define('page-loader', PageLoader);

export default PageLoader
