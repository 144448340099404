import { Api, isPlainObject } from "infrontjs";

class DvzApi extends Api
{
    static getErrorFromResponse( res )
    {
        let apiError = null;
        if ( res && isPlainObject( res ) && res.hasOwnProperty( 'code' ) && 299 < +res.code )
        {
            apiError = res;
        }
        return apiError;
    }

    constructor( endpointUrl )
    {
        super( endpointUrl, { "Content-type" : "application/json" });
    }

    async destroySession( sessionId )
    {
        // Fire and forget
        this.delete( 'v1/auth/session/' + encodeURIComponent( sessionId ) );
    }

    async getSession( sessionId )
    {
        let session = null;
        try
        {
            let res = await this.get( 'v1/auth/session/' + encodeURIComponent( sessionId ) );
            if ( res && +res.code === 200 && res.hasOwnProperty( 'data' ) )
            {
                session = res.data;
            }
        }
        catch( e )
        {
            session = null;
        }
        return session;
    }

    setSessionId( sessionId )
    {
        this.headers.set( 'sessionid', '' + sessionId );
    }
}

export { DvzApi };
