import { App } from "infrontjs";
import { Gui } from "./Gui";

import { DvzApi } from "./DvzApi.js";
import { Session } from "../model/Session.js";

// States
import { AuthLoginState } from "../state/auth/AuthLoginState.js";
import { AuthLogoutState } from "../state/auth/AuthLogoutState.js";
import { KpiGenderState } from "../state/kpi/KpiGenderState.js";
import { DashboardState } from "../state/dashboard/Dashboard.js";

// Configuration
import GlobalConfig from './../config/globalConfig.json';
import LocalConfig from './../config/localConfig.json';


class DvzApp extends App
{
    constructor( props )
    {
        super( props );

        // Config
        this.config = { ...GlobalConfig, ...LocalConfig };
        this.session = null;

        // Add more useful logic  to app instance
        this.api = new DvzApi( this.config.api.url );
        this.gui = new Gui( this );

        // App specific stuff
        this.initRoutesAndStates();
    }

    async run( route )
    {
        await this.gui.init();
        await this.resolveSession();
        await super.run( route );
    }

    async resolveSession()
    {
        const localSessionId = localStorage.getItem( 'sessionId' );
        if ( localSessionId )
        {
            const serverSession = await this.api.getSession( localSessionId );
            if ( serverSession )
            {
                this.session = new Session( serverSession );
                this.api.setSessionId( this.session.id );
            }
            else
            {
                localStorage.removeItem( 'sessionId' );
            }
        }
    }

    initRoutesAndStates()
    {
        this.router.addRoute( '/auth/login', AuthLoginState );
        this.router.addRoute( '/auth/logout', AuthLogoutState );
        this.router.addRoute( '/kpi/gender', KpiGenderState );
        this.router.addRoute( '/dashboard', DashboardState );
        this.router.addRoute( '/', DashboardState );
    }
}

export { DvzApp };
