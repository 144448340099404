import 'bootstrap/dist/css/bootstrap.min.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import '../assets/css/fabrx-styles.css';
import '../assets/css/style.css';

import './components/general/page-loader';

import { Tooltip, Toast, Popover, Modal } from 'bootstrap';
import { DvzApp } from "./core/DvzApp.js";

customElements.whenDefined( 'page-loader' ).then( () =>
{
    //App.create( { "root" : document.getElementById( 'app' ) } );
    const app = new DvzApp(
        {
            "title" : "Diversitize | App",
            "container" : document.getElementById( 'app' )
        }
    );
    app.run();
    setTimeout( () =>
    {
        app.gui.pageLoader.fadeOut();
    }, 1000 );
});
