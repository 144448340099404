import { State } from "infrontjs";

class AuthLogoutState extends State
{
    static ID = 'auth-logout';

    canEnter()
    {
        return true;
    }

    enter()
    {
        this.app.api.destroySession( localStorage.getItem( 'sessionId' ) );
        localStorage.removeItem( 'sessionId' );
        this.app.api.setSessionId( '' );
        this.app.session = null;
        this.app.router.redirect( '/auth/login' );
    }
}

export { AuthLogoutState };
