import { PropertyObject } from "infrontjs";

class Session extends PropertyObject
{
    constructor( props = {} )
    {
        super({
            'id' : props.hasOwnProperty( 'id' ) ? props.id : null,
            'user' : props.hasOwnProperty('user' ) ? props.user : null
        });
    }

    getSessionId()
    {
        return this.id;
    }

    getUser()
    {
        return this.user;
    }
}

export { Session };
