import { State } from "infrontjs";

class DashboardState extends State
{
    static ID = 'dashboard';

    canEnter()
    {
        return ( null !== this.app.session );
    }

    getRedirectTo()
    {
        return '/auth/login';
    }


    async enter()
    {
        const contentTmpl = await this.app.templateManager.get( '/tmpl/dashboard.html' );

        const contentHtml = await this.app.templateManager.getHtml(
            contentTmpl,
            {
                company : this.app.session.user.company,
            }
        );

        this.app.gui.renderPage(
            "Dashboard",
            contentHtml,
            "dashboard"
        );
    }
}

export{ DashboardState };
