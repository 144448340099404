import { App } from "infrontjs";
import template from './template.html';

class AnalyzeConfiguration extends HTMLElement
{
    constructor()
    {
        super();
        this._isConnected = false;
        //this._width = 280;
    }

    connectedCallback()
    {
        if ( false === this._isConnected )
        {
            this.root = this;
            this.app = App.get();
            this._isConnected = true;
            this._kpiData = JSON.parse( this.getAttribute( 'data-kpi' ) );
            this.render();
            const self = this;
            this.root.querySelectorAll( '[data-type="card-switch"]' ).forEach( function( elem, index )
            {
                elem.addEventListener( 'change', self.onSwitchChange.bind( self ) );
            });
            this.root.querySelector( '[data-id="btnReset"]' ).addEventListener( 'click', this.onReset.bind( this ) );
            //this.root.querySelector( '[data-id="btnShowPanel"]' ).addEventListener( 'click', this.showPanel.bind( this ) );
        }
        //root.innerHTML =app.templateManager.compileTemplate( template, { imgLogo : dvzLogo, coverImg : coverImg } ) ;
    }

    disconnectedCallback()
    {}

    onReset( e )
    {
        this.root.querySelectorAll( '[data-type="card-switch"]' ).forEach( function( elem, index )
        {
            elem.checked = true;
        });

        this.onSwitchChange();
    }

    onSwitchChange( e )
    {
        const cards = [];

        this.root.querySelectorAll( '[data-type="card-switch"]' ).forEach( function( elem, index )
        {
            cards.push(
                {
                    "id" : elem.value,
                    "visible" : elem.checked
                }
            )
        });

        this.root.dispatchEvent(
            new CustomEvent(
                "update",
                {
                    "detail" : {
                        "cards" : cards
                    }
                }
            )
        );
    }

    attributeChangedCallback( name, oldVal, newVal )
    {
        if ( this._isConnected )
        {
            this.render();
        }
    }

    render()
    {
        this.root.innerHTML = this.app.templateManager.getHtml( template, { kpi : this._kpiData} );
    }

}

customElements.define('analyze-configuration', AnalyzeConfiguration );
