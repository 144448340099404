// Import all global web component
import './../components/general/alert-box';
import './../components/general/side-navigation';
import './../components/general/bread-crumb';

class Gui
{
    constructor( appInstance )
    {
        this.app = appInstance;

        document.querySelector('body').style.opacity = '1';

        this.pageLoader = document.querySelector( 'page-loader' );
        this.tmplLayout = null;
    }

    async init()
    {
        this.tmplLayout = await this.app.templateManager.get( '/tmpl/main-layout.html' );
    }

    async renderPage( breadCrumb, body, activeMenuItem )
    {
        const hasMainPageAlreadyRendered = ( null !== this.app.container.querySelector( 'side-navigation' ) );

        if ( false === hasMainPageAlreadyRendered )
        {
            // Render complete page
            const pageHtml = this.app.templateManager.getHtml(
                this.tmplLayout,
                {
                    crumbs : breadCrumb,
                    content : body,
                    activeMenuItem : activeMenuItem
                }
            );
            this.app.container.innerHTML = pageHtml;

            // Navigation logic
            document.querySelectorAll('.back-arrow').forEach(fabrxSidebarSwitcher => {
                fabrxSidebarSwitcher.addEventListener('click', (e) => {
                    if (document.body.className.match('sidebar-compact')) {
                        document.querySelector('body').classList.toggle('sidebar-compact');
                        document.querySelector('body').classList.add('previous-compact');
                    }
                    else if (document.body.className.match('previous-compact')) {
                        document.querySelector('body').classList.toggle('sidebar-compact');
                    }
                    else {
                        document.querySelector('body').classList.toggle('sidebar-icons');
                    }
                });
            });

            document.querySelectorAll('.aside-toggle').forEach(fabrxToggle => {
                fabrxToggle.addEventListener('click', ({target}) => {
                    target.closest('body').classList.toggle('manage-page');
                    target.closest('.fabrx-sidebar').classList.toggle('aside-open');
                });
            });
        }
        else
        {
            // Only update the relevant parts
            this.app.container.querySelector( 'bread-crumb' ).setAttribute( 'crumbs', breadCrumb );
            this.app.container.querySelector( 'side-navigation' ).setAttribute( 'active-item', activeMenuItem );
            this.app.container.querySelector( '[data-id="inapp-content"]' ).innerHTML = body;
        }
    }

    scrollToTop()
    {
        window.scrollTo(
            {
                top: 0,
                behavior : 'smooth'
            }
        );
    }

}

export { Gui };
