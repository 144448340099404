import { App } from "infrontjs";

import template from './template.html';

class BreadCrumb extends HTMLElement
{
    static get observedAttributes()
    {
        return [ 'crumbs' ];
    }

    constructor()
    {
        super();
        this._isConnected = false;
    }

    connectedCallback()
    {
        if ( false === this._isConnected )
        {
            this.root = this;
            this.app = App.get();
            this._isConnected = true;
            this._crumbs = this.getAttribute( 'crumbs' ).split( "," );
            this.render();
        }
    }

    disconnectedCallback()
    {}

    attributeChangedCallback( name, oldVal, newVal )
    {
        if ( this._isConnected )
        {
            this._crumbs = this.getAttribute( 'crumbs' ).split( "," );
            this.render();
        }
    }

    render()
    {
        this.root.innerHTML = this.app.templateManager.getHtml( template,{ crumbs : this._crumbs } );
    }

}

customElements.define('bread-crumb', BreadCrumb );
