import { State } from "infrontjs";
import { DvzApi } from "../../core/DvzApi.js";

import tippy from "tippy.js";
import { Modal } from 'bootstrap';
import './../../components/general/chartjs-chart';
import './../../components/kpi/analyze-configuration';

class KpiGenderState extends State
{
    static ID = 'kpi-gender';

    canEnter()
    {
        return ( null !== this.app.session );
    }

    getRedirectTo()
    {
        return '/auth/login';
    }


    async enter()
    {
        const resDistincts = await this.app.api.get( '/v1/stat/distinct-attributes' ),
              contentTmpl = await this.app.templateManager.get( '/tmpl/kpi-gender-dashboard.html' );

        this.modalTmpl = await this.app.templateManager.get( '/tmpl/kpi-chart-modal.html' );

        let err = DvzApi.getErrorFromResponse( resDistincts );
        if ( null !== err )
        {
            throw new Error( 'No distincts data received.' );
        }

        const departments = [];
        for ( let di = 0; di < resDistincts.data.departments.length; di++ )
        {
            departments.push(
                {
                    "id" : "department-" + this._createHash( resDistincts.data.departments[ di ] ),
                    "title" : resDistincts.data.departments[ di ],
                    "chartType" : "doughnut",
                    "chartData" : null
                }
            )
        }

        this.kpiData = [
            {
                "id" : "company",
                "title" : "Unternehmen",
                "elements" : [
                    { "id" : "employeeBySex", "title" : "Beschäftigte", "chartType" : "doughnut", "chartData" : null },
                    { "id" : "employmentTypeBySex", "title" : "Befristung der Anstellung", "chartType" : "bar-stacked", "chartData" : null },
                    { "id" : "workingTimeModelBySex", "title" : "Arbeitszeitmodelle", "chartType" : "bar-stacked", "chartData" : null }
                ]
            },

            {
                "id" : "career",
                "title" : "Karriere",
                "elements" : [
                    { "id" : "leadingPositionBySex", "title" : "Führungskräfte", "chartType" : "doughnut", "chartData" : null },
                    { "id" : "leadingPositionLevelBySex", "title" : "Führungspositionslevel", "chartType" : "bar-stacked", "chartData" : null },
                    { "id" : "loanBySex", "title" : "Löhne in Euro", "chartType" : "bar", "chartData" : null },
                    { "id" : "seniorityBySex", "title" : "Betriebszugehörigkeit in Jahren", "chartType" : "bar", "chartData" : null }
                ]
            },

            {
                "id" : "departments",
                "title" : "Abteilungen",
                "elements" : departments
            },

            {
                "id" : "parentship",
                "title" : "Familie",
                "elements" : [
                    { "id" : "parentBySex", "title" : "Elternschaft", "chartType" : "doughnut", "chartData" : null },
                    { "id" : "parentalLeaveBySex", "title" : "Elternzeit", "chartType" : "doughnut", "chartData" : null },
                    { "id" : "parentalLeaveBySexInMonth", "title" : "Elternzeit in Monaten", "chartType" : "bar", "chartData" : null }
                ]
            }
        ];

        const contentHtml = await this.app.templateManager.getHtml(
            contentTmpl,
            {
                company : this.app.session.user.company,
                distincts : resDistincts.data,
                kpiData : this.kpiData
            }
        );

        this.app.gui.renderPage(
            "KPI,Geschlecht",
            contentHtml,
            "kpi,gender"
        );

        const res = await this.app.api.get( '/v1/stat/kpi' ),
              stats = res && res.code === 200 ? res.data : null;

        // @todo
        // Rework to use origData in all modal popups
        // Check if setChartData should be used with origData instead

        // start: Company
        let data = {
            labels : ['weiblich', 'männlich', 'divers'],
            values: [ +stats.employeeBySex.f, +stats.employeeBySex.m, +stats.employeeBySex.d ],
            origData: stats.employeeBySex
        };
        this._setChartData( "company.employeeBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="employeeBySex"]' ).initialize( data );
        if ( true === this._isBenchmarkExceeded( stats.employeeBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="company.employeeBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="company.employeeBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Unbefristet', 'Befristet' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.employmentTypeBySex.permanent.f, stats.employmentTypeBySex.temporary.f ]
                },
                {
                    label : 'männlich',
                    data : [ stats.employmentTypeBySex.permanent.m, stats.employmentTypeBySex.temporary.m ]
                },
                {
                    label : 'divers',
                    data : [ stats.employmentTypeBySex.permanent.d, stats.employmentTypeBySex.temporary.d ]
                }
            ],
            origData : stats.employmentTypeBySex
        };
        this._setChartData( "company.employmentTypeBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="employmentTypeBySex"]' ).initialize( data, false );
        if ( true === this._isBenchmarkExceeded( stats.employmentTypeBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="company.employmentTypeBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="company.employmentTypeBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Vollzeit', 'Teilzeit' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.workingTimeModelBySex.fullTime.f, stats.workingTimeModelBySex.partTime.f ]
                },
                {
                    label : 'männlich',
                    data : [ stats.workingTimeModelBySex.fullTime.m, stats.workingTimeModelBySex.partTime.m ]
                },
                {
                    label : 'divers',
                    data : [ stats.workingTimeModelBySex.fullTime.d, stats.workingTimeModelBySex.partTime.d ]
                }
            ],
            origData : stats.workingTimeModelBySex
        };
        this._setChartData( "company.workingTimeModelBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="workingTimeModelBySex"]' ).initialize( data, false );
        if ( true === this._isBenchmarkExceeded( stats.workingTimeModelBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="company.workingTimeModelBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="company.workingTimeModelBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }
        // stop: company

        // start: Karriere
        data = {
            labels : ['weiblich', 'männlich', 'divers'],
            values:  [ +stats.leadingPositionBySex.f, +stats.leadingPositionBySex.m, +stats.leadingPositionBySex.d ],
            origData : stats.leadingPositionBySex
        };
        this._setChartData( "career.leadingPositionBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="leadingPositionBySex"]' ).initialize( data );
        if ( true === this._isBenchmarkExceeded( stats.leadingPositionBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="career.leadingPositionBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="career.leadingPositionBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Niedrig', 'Mittel', 'Hoch' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.leadingPositionLevelBySex.low.f, stats.leadingPositionLevelBySex.middle.f, stats.leadingPositionLevelBySex.top.f ]
                },
                {
                    label : 'männlich',
                    data : [ stats.leadingPositionLevelBySex.low.m, stats.leadingPositionLevelBySex.middle.m, stats.leadingPositionLevelBySex.top.m ]
                },
                {
                    label : 'divers',
                    data : [ stats.leadingPositionLevelBySex.low.d, stats.leadingPositionLevelBySex.middle.d, stats.leadingPositionLevelBySex.top.d ]
                }
            ],
            origData : stats.leadingPositionLevelBySex
        };
        this._setChartData( "career.leadingPositionLevelBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="leadingPositionLevelBySex"]' ).initialize( data, false );
        if ( true === this._isBenchmarkExceeded( stats.leadingPositionLevelBySex.low.benchmarks ) ||
             true === this._isBenchmarkExceeded( stats.leadingPositionLevelBySex.middle.benchmarks ) ||
             true === this._isBenchmarkExceeded( stats.leadingPositionLevelBySex.top.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="career.leadingPositionLevelBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="career.leadingPositionLevelBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Minimum', 'Median', 'Maximum' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.loanBySex.f.low, stats.loanBySex.f.median, stats.loanBySex.f.top ]
                },
                {
                    label : 'männlich',
                    data : [ stats.loanBySex.m.low, stats.loanBySex.m.median, stats.loanBySex.m.top ]
                },
                {
                    label : 'divers',
                    data : [ stats.loanBySex.d.low, stats.loanBySex.d.median, stats.loanBySex.d.top ]
                }
            ],
            origData : stats.loanBySex
        };
        this._setChartData( "career.loanBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="loanBySex"]' ).initialize( data, false );
        if ( true === this._isBenchmarkExceeded( stats.loanBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="career.loanBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="career.loanBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Minimum', 'Durchschnitt', 'Maximum' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.seniorityBySex.f.low, stats.seniorityBySex.f.avg, stats.seniorityBySex.f.top ]
                },
                {
                    label : 'männlich',
                    data : [ stats.seniorityBySex.m.low, stats.seniorityBySex.m.avg, stats.seniorityBySex.m.top ]
                },
                {
                    label : 'divers',
                    data : [ stats.seniorityBySex.d.low, stats.seniorityBySex.d.avg, stats.seniorityBySex.d.top ]
                }
            ],
            origData : stats.seniorityBySex
        };
        this._setChartData( "career.seniorityBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="seniorityBySex"]' ).initialize( data, false );
        if ( true === this._isBenchmarkExceeded( stats.seniorityBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="career.seniorityBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="career.seniorityBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }
        // stop: Karriere

        // start: Departments
        for ( const [dName, dValues] of Object.entries( stats.departmentBySex ) )
        {
            data = {
                labels : ['weiblich', 'männlich', 'divers'],
                values:  [ +dValues.f, +dValues.m, +dValues.d ],
                origData : dValues
            };
            this._setChartData( "departments.department-" + this._createHash(dName ), data );
            this.app.container.querySelector( 'chartjs-chart[data-id="department-' + this._createHash(dName ) +'"]' ).initialize( data );
            if ( true === this._isBenchmarkExceeded( dValues.benchmarks ) )
            {
                this.app.container.querySelector( '[data-chart-id="departments.department-' + this._createHash(dName ) + '"][data-type="chartModal"]' ).classList
                    .add( "fabrx-status" );
                this.app.container.querySelector( '[data-chart-id="departments.department-' + this._createHash(dName ) + '"][data-type="chartModal"]' ).classList
                    .add( "dnd" );
            }
        }
        // stop: Departments


        // start: Parentship
        data = {
            labels : ['weiblich', 'männlich', 'divers'],
            values:  [ +stats.parentBySex.f, +stats.parentBySex.m, +stats.parentBySex.d ],
            origData : stats.parentBySex
        };
        this._setChartData( "parentship.parentBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="parentBySex"]' ).initialize(data );
        if ( true === this._isBenchmarkExceeded( stats.parentBySex.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="parentship.parentBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="parentship.parentBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : ['weiblich', 'männlich', 'divers'],
            values:  [ +stats.parentalLeaveBySexTotal.f, +stats.parentalLeaveBySexTotal.m, +stats.parentalLeaveBySexTotal.d ],
            origData : stats.parentalLeaveBySexTotal
        };
        this._setChartData( "parentship.parentalLeaveBySex", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="parentalLeaveBySex"]' ).initialize( data );
        if ( true === this._isBenchmarkExceeded( stats.parentalLeaveBySexTotal.benchmarks ) )
        {
            this.app.container.querySelector( '[data-chart-id="parentship.parentalLeaveBySex"][data-type="chartModal"]' ).classList
                .add( "fabrx-status" );
            this.app.container.querySelector( '[data-chart-id="parentship.parentalLeaveBySex"][data-type="chartModal"]' ).classList
                .add( "dnd" );
        }

        data = {
            labels : [ 'Minimum', 'Durchschnitt', 'Maximum' ],
            values: [
                {
                    label : 'weiblich',
                    data : [ stats.parentalLeaveBySex.f.low, stats.parentalLeaveBySex.f.avg, stats.parentalLeaveBySex.f.top ]
                },
                {
                    label : 'männlich',
                    data : [ stats.parentalLeaveBySex.m.low, stats.parentalLeaveBySex.m.avg, stats.parentalLeaveBySex.m.top ]
                },
                {
                    label : 'divers',
                    data : [ stats.parentalLeaveBySex.d.low, stats.parentalLeaveBySex.d.avg, stats.parentalLeaveBySex.d.top ]
                }
            ]
        };
        this._setChartData( "parentship.parentalLeaveBySexInMonth", data );
        this.app.container.querySelector( 'chartjs-chart[data-id="parentalLeaveBySexInMonth"]' ).initialize( data, false );

        // stop: Parentship

        this._initEvents();
    }

    exit()
    {
        this._removeEvents();
    }

    _initEvents()
    {
        this.app.container.querySelector( 'analyze-configuration' ).addEventListener(
            'update',
            this._onAnalyzeConfigurationUpdate.bind( this )
        );

        this.app.container.querySelectorAll( '[data-type="chartModal"] ').forEach( function( elem, idx )
        {
            elem.addEventListener( 'click', this._showChartModal.bind( this ) );
        }.bind( this ));
    }

    _removeEvents()
    {
        this.app.container.querySelector( 'analyze-configuration' ).removeEventListener(
            'update',
            this._onAnalyzeConfigurationUpdate.bind( this )
        );

        this.app.container.querySelectorAll( '[data-type="chartModal"] ').forEach( function( elem, idx )
        {
            elem.removeEventListener( 'click', this._showChartModal.bind( this ) );
        }.bind( this ));
    }

    _setChartData( id, data )
    {
        const elem = this._getChartData( id );
        if ( !elem )
        {
            throw new Error( 'Invalid id. Elem not found.' );
        }

        elem.chartData = data;
    }

    _getChartData( id )
    {
        const ids = id.split( "." );
        let elem = null;

        if ( ids.length !== 2 )
        {
            throw new Error( 'Invalid chart data id.' );
        }

        for ( let ki = 0; ki < this.kpiData.length; ki++ )
        {
            if ( this.kpiData[ ki ].id === ids[ 0 ] )
            {
                for ( let ei = 0; ei < this.kpiData[ ki ].elements.length; ei++ )
                {
                    if ( this.kpiData[ ki ].elements[ ei ].id === ids[ 1 ] )
                    {
                        elem = this.kpiData[ ki ].elements[ ei ];
                        break;
                    }
                }

                break;
            }
        }

        return elem;
    }

    _showChartModal( e )
    {
        const chartId = e.target.getAttribute( 'data-chart-id' ),
              chartData = this._getChartData( chartId ),
              modalHtml = this.app.container.querySelector( '[data-id="chartModal"]' );

        modalHtml.querySelector( '[data-id="content"]' ).innerHTML = this.app.templateManager.getHtml(
            this.modalTmpl, { data: chartData });

        const myModal = new Modal( this.app.container.querySelector( '[data-id="chartModal"]' ) );
        myModal.show();
    }

    _onAnalyzeConfigurationUpdate( e )
    {
        const cards = e.detail.cards;
        let isInAnalyzeMode = false;
        for ( let ci = 0; ci < cards.length; ci++ )
        {
            if ( true === cards[ ci ].visible )
            {
                this.app.container.querySelector( '[data-id="card-' + cards[ ci ].id + '"]' ).style.display = "block";
            }
            else
            {
                this.app.container.querySelector( '[data-id="card-' + cards[ ci ].id + '"]' ).style.display = "none";
                isInAnalyzeMode = true;
            }
        }


        if  ( true === isInAnalyzeMode )
        {
            this.app.container.querySelectorAll( '[data-type="kpi-headline"]' ).forEach( function( elem, idx )
            {
                elem.style.display = 'none';
            });

            this.app.container.querySelector( '[data-id="headline-analyse-mode"]' ).style.display = 'block';
        }
        else
        {
            this.app.container.querySelector( '[data-id="headline-analyse-mode"]' ).style.display = 'none';
            this.app.container.querySelectorAll( '[data-type="kpi-headline"]' ).forEach( function( elem, idx )
            {
                elem.style.display = 'block';
            });
        }

    }

    _isBenchmarkExceeded( benchmarks )
    {
        return ( true === benchmarks.isExceededD || true === benchmarks.isExceededM || true === benchmarks.isExceededD );
    }

    _createHash( str, seed = 1 )
    {
        let h1 = 0xdeadbeef ^ seed,
            h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }

        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return ( 4294967296 * (2097151 & h2) + (h1 >>> 0) ).toString( 16 );
    }
}

export{ KpiGenderState };
