import { App } from "infrontjs";

import logo from './../../../../assets/images/dvz-logo.png';
import logoIcon from './../../../../assets/images/dvz-logo-icon.png';

import template from './template.html';

class SideNavigation extends HTMLElement
{
    static get observedAttributes()
    {
        return [ 'active-item' ];
    }

    constructor()
    {
        super();
        this._isConnected = false;
        this._menuData = [

        ];
    }

    connectedCallback()
    {
        if ( false === this._isConnected )
        {
            this.root = this;
            this.app = App.get();
            this._isConnected = true;
            this._activeItem = this.getAttribute( 'active-item' ).split( "," );
            this.render();
        }
    }

    disconnectedCallback()
    {}

    attributeChangedCallback( name, oldVal, newVal )
    {
        if ( this._isConnected )
        {
            this._activeItem = this.getAttribute( 'active-item' ).split( "," );
            this.render();
        }
    }

    render()
    {
        this.root.innerHTML = this.app.templateManager.getHtml( template,{ activeItem: this._activeItem } );
        this.root.querySelectorAll('.menu-category li').forEach((fabrxNavItem) => {
            fabrxNavItem.addEventListener('mouseover', (e) => {
                var fabrxPosition = fabrxNavItem.getBoundingClientRect();
                fabrxNavItem.style.top = fabrxPosition.top + 'px';
            });
        });
    }

}

customElements.define('side-navigation', SideNavigation );
