import template from './template.html';
import { Chart, registerables } from "chart.js";
import { App } from "infrontjs";

class ChartJsChart extends HTMLElement
{
    constructor()
    {
        super();
        this._isConnected = false;
        this._isStackedBar = false;

        this.borderWidth = 1;
        this.transparentColors = [
            'rgba(255, 104, 210, 0.2)',
            'rgba(6, 195, 255, 0.2)',
            'rgba(252, 233, 3, 0.2)'
        ];

        this.filledColors = [
            'rgba(255, 104, 210, 1)',
            'rgba(6, 195, 255, 1)',
            'rgba(252, 233, 3, 1)'
        ];

        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            animations: {
                tension: {
                    duration: 1000,
                    easing: 'linear',
                    from: 1,
                    to: 0,
                    loop: true
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                title: {
                    display: false,
                    text: 'no title'
                }
            }
        };
    }

    connectedCallback()
    {
        if ( false === this._isConnected )
        {
            this.root = this;
            this.app = App.get();
            Chart.register( ...registerables );
            this._isConnected = true;
            this._isInitialized = false;
            this.type = this.getAttribute( 'type' );

            if ( this.type === 'doughnut' )
            {
                this.options.plugins.tooltip = {
                    callbacks : {
                        label: function( context )
                        {
                            let percSuff = '';
                            if ( context.dataset.data && context.dataset.data.length )
                            {
                                let total = 0,
                                    perc = 0;
                                for ( let di = 0; di < context.dataset.data.length; di++ )
                                {
                                    total += parseInt( context.dataset.data[ di ] );
                                }

                                perc = +context.parsed /  total;
                                perc *= 100;
                                percSuff = ` [${(''+perc.toFixed(1)).replace( '.', ',')}%]`;
                            }
                            return `${context.label}: ${context.parsed}${percSuff}`;
                        }
                    }
                };
            }
            else if ( 'bar-stacked' === this.type )
            {
                this.options.scales = {
                    x : { stacked : true },
                    y : { stacked: true }
                };
                this.type = 'bar';
            }

            this.render();
        }
    }

    disconnectedCallback()
    {}

    initialize( data, isSingleDataSet = true )
    {
        if ( true === this._isInitialized )
        {
            return;
        }

        this._isInitialized = true;
        this.querySelector( '[data-id="spinner"]' ).style.display = 'none';
        this.querySelector( '[data-id="canvas"]' ).style.display = 'inline-block';

        const datasets = [];

        if ( true === isSingleDataSet )
        {
            datasets.push(
                {
                    data : data.values,
                    backgroundColor: this.filledColors,
                    borderColor: this.transparentColors,
                    borderWidth: this.borderWidth
                }
            );
        }
        else
        {
            for ( let di = 0; di < data.values.length; di++ )
            {
                datasets.push(
                    {
                        data : data.values[ di ].data,
                        label : data.values[ di ].label,
                        backgroundColor: this.filledColors[ di ],
                        borderColor: this.transparentColors[ di ],
                        borderWidth : this.borderWidth
                    }
                );
            }
        }

        this.chartJsInstance = new Chart(
            this.querySelector( '[data-id="canvas"]' ),
            {
                type : this.type,
                data: {
                    labels: data.labels,
                    datasets: datasets
                },
                options : this.options
            },
        );
    }

    render()
    {
        this.root.innerHTML = this.app.templateManager.getHtml( template );
        //this.root.querySelector( '[data-id="chart-canvas-container"]' ).style.height = '340px';
    }
}


// Make it accessible
customElements.define('chartjs-chart', ChartJsChart );
export default ChartJsChart;
